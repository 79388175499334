import * as React from 'react'
import { ILink, Link } from '../link'

import { SubnavWrapper, SubnavTitle, SubnavList } from './styles'

interface ISubNav {
  title?: string
  navItems: ILink[]
}

export const Subnav = ({ title, navItems = [] }: ISubNav) => (
  <SubnavWrapper  data-testid="subnav">
    {title && <SubnavTitle>{title}</SubnavTitle>}
    <SubnavList>
      {
      navItems.map((link: ILink) => (
        <li key={link.title}>
          <Link {...link} />
        </li>
      ))
      }
    </SubnavList>
  </SubnavWrapper>
)
