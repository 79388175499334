import tw from 'twin.macro'

export const Section = tw.section`
  bg-secondary-block
`

export const ContentIndexWrapper = tw.div`
  container mx-auto flex flex-wrap flex-col lg:flex-row
`

export const CardsContainer = tw.div`
  lg:w-3/4
`

export const NavContainer = tw.div`
  lg:w-1/4 lg:mx-0 mx-10
`

export const Seperator = tw.hr`
  w-full border-b border-secondary-block opacity-25 my-0 py-0 -mt-10 lg:hidden
`
