import * as React from 'react'
import { graphql } from "gatsby"

import { ContentIndex } from '../../sections/content-index/index'
import { PageLayout } from '../../components/page-layout'
import { ICard } from '../../sections/cards'
import { ILink } from '../../components/link'

interface IBlogPage {
  data: any
}

export const BlogPage = ({ data }: IBlogPage) => {
  const { allPages, indexPages, allContentfulPage, allFooterNavigation, contentfulSiteSettings } = data
  
  const footerNavItems = allFooterNavigation.nodes
  const { metaTitlePrefix, headerCta } = contentfulSiteSettings

  const nav: ILink[] = []
  allPages.nodes.forEach((node: any) => {
    const dateForSlug = node.dateForSlug.toLowerCase()
      const navCreated = nav.find((navItem) => navItem.id === dateForSlug)
      if (!navCreated) {
        nav.push({
          id: dateForSlug,
          title: node.createdAt,
          page: { slug: `blog/${dateForSlug}` }
        } as ILink)
      }
  })

  const cards: ICard[] = []
  indexPages.nodes.forEach((node: any) => {
    cards.push({
      ...node,
      subtitle: node.author?.name,
      body: node.body,
      ...node.banner?.image ? { image: node.banner.image } : {},
      link: { 
        id: node.id,
        title: 'Read more',
        page: {
          slug: node.slug
        }
      } as ILink
    })
  })

  return (
    <PageLayout
      title="Blog"
      footerNavItems={footerNavItems}
      navItems={allContentfulPage.nodes}
      metaTitlePrefix={metaTitlePrefix}
      headerCta={headerCta}
      currentPage="blog"
    >
      <ContentIndex title="Blog" cards={cards} navigation={{ byDate: nav }} />
    </PageLayout>
  )
}

export const blogPageQuery = graphql`
  query blogPageQuery($begin: Date, $end: Date) {
    indexPages: allContentfulPage(
      filter: {
        pageType: {eq: "Blog"}
        createdAt: {gte: $begin, lte: $end}
      }
    ) {
      nodes {
        ...PageContent
        createdAt(formatString: "MMM YYYY")
        body {
          raw
          references {
            contentful_id
            url
            alt
          }
        }
      }
    }

    allPages: allContentfulPage(
      filter: {
        pageType: {eq: "Blog"}
      }
    ) {
      nodes {
        createdAt(formatString: "MMM YYYY")
        dateForSlug: createdAt(formatString: "MMM-YYYY")
      }
    }

    contentfulSiteSettings {
      headerCta {
        ...LinkContent
      }
      metaTitlePrefix
    }

    allContentfulPage {
      nodes {
        ...PageContent
      }
    }

    allFooterNavigation {
      nodes {
        ...FooterNavigationContent
      }
    }
  }
`

export default BlogPage
