import * as React from 'react'

import { Subnav } from '../../components/subnav'
import { ILink } from '../../components/link'
import { Cards, ICard } from '../cards'

import { Section, ContentIndexWrapper, CardsContainer, NavContainer, Seperator } from './styles'

interface ISubNav {
  byDate: ILink[]
}

export interface IContentIndex {
  title: string
  navigation: ISubNav
  cards: ICard[]
}

export const ContentIndex = ({ title, navigation, cards }: IContentIndex) => {
  return (
    <Section>
      <ContentIndexWrapper>
      <CardsContainer>
        <Cards id='blog-cards' title={title} cards={cards.filter((card: any) => card)} asSnippets />
      </CardsContainer>
      <Seperator />
      <NavContainer>
        <Subnav title='Posts by date' navItems={navigation.byDate} />
      </NavContainer>
      </ContentIndexWrapper>
    </Section>
  )
}
