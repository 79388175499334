import tw from 'twin.macro'

export const SubnavWrapper = tw.div`
  p-2
`

export const SubnavTitle = tw.p`
  font-bold
`

export const SubnavList = tw.ul`
  list-none m-0 p-0
  [> li]:(pb-4)
`
